import { MetadataFunction } from "../types"

const marketEvaluationReportMetadata: MetadataFunction = opts => ({
  title: "Comprehensive Market Evaluation Report",
  description: "",
  canonical: "https://www.honestdoor.com/products/market-evaluation-report",
  openGraph: {
    url: "https://www.honestdoor.com/products/market-evaluation-report",
    ...opts?.overrides?.openGraph,
  },
  ...opts?.overrides,
})

export default marketEvaluationReportMetadata

import { forgePlaceInformation, forgePlaceURL } from ".."
import { MetadataFunction, Place } from "../types"

type PermitsMetadataOpts = {
  place: Place
}

const permitsMetadata: MetadataFunction<PermitsMetadataOpts> = opts => ({
  title: forgePlaceInformation(opts?.place, {
    default: "Canada building permits - development & construction, renovations of homes",
    province: `${opts?.place.province} building permits - development & construction, renovations of homes`,
    city: `${opts?.place.city} building permits - development & construction, renovations of homes`,
    neighbourhood: `${opts?.place.neighbourhood} ${opts?.place.city} building permits - development & construction, renovations of homes`,
  }),
  description: forgePlaceInformation(opts?.place, {
    default: `Full listing building & development permits in Canada. Find newly issued construction and redevelopment permits for homes and commercial spaces on HonestDoor`,
    city: `All city of ${opts?.place.city}'s building & development permits. Find out new issued construction and redevelopment permits for homes and commercial spaces in ${opts?.place.city}, ${opts?.place.province}`,
    neighbourhood: `All of ${opts?.place.neighbourhood} ${opts?.place.city}, ${opts?.place.province} building & development permits. Find out new issued construction and redevelopment permits for homes and commercial spaces`,
  }),
  canonical: forgePlaceURL(opts?.place, "/permits"),
  openGraph: {
    url: forgePlaceURL(opts?.place, "/permits"),
    ...opts?.overrides?.openGraph,
  },
  ...opts?.overrides,
})

export default permitsMetadata

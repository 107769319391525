import { MetadataFunction } from "../types"

const privateListingsMetadata: MetadataFunction = opts => ({
  title: "Properties for Sale",
  description: "View private listings",
  canonical: "https://www.honestdoor.com/listings",
  openGraph: {
    url: "https://www.honestdoor.com/listings",
    ...opts?.overrides?.openGraph,
  },
  ...opts?.overrides,
})

export default privateListingsMetadata

import { MetadataFunction } from "../types"

const profileMetadata: MetadataFunction = opts => ({
  title: "My Profile",
  nofollow: true,
  noindex: true,
  ...opts?.overrides,
})

export default profileMetadata

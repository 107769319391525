import { MetadataFunction } from "../types"

const widgetMetadata: MetadataFunction = opts => ({
  title: "Instant Home Value Widget",
  description:
    "Get the HonestDoor instant home value widget for your website. Let your clients instantly get the value of their home. What's My Home Worth? Find out your property's value with Canada's most accurate home estimate tool.",
  canonical: "https://www.honestdoor.com/instant-home-value-widget",
  openGraph: {
    url: "https://www.honestdoor.com/instant-home-value-widget",
    ...opts?.overrides?.openGraph,
  },
  ...opts?.overrides,
})

export default widgetMetadata

import { MetadataFunction } from "../types"

const homewiseMetadata: MetadataFunction = opts => ({
  title: "Get Approved for a Mortgage in Minutes",
  description:
    "Get a low rate with Canada’s best online mortgage. Better mortgage process for Canadians to compare rates, save money, and find the best-matched lender.",
  canonical: "https://www.honestdoor.com/homewise-mortgage",
  openGraph: {
    url: "https://www.honestdoor.com/homewise-mortgage",
    ...opts?.overrides?.openGraph,
  },
  ...opts?.overrides,
})

export default homewiseMetadata

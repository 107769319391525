import { forgePlaceInformation, forgePlaceURL } from ".."
import { MetadataFunction, Place } from "../types"

type RecentlySoldMetadataOpts = {
  place: Place
}

const recentlySoldMetadata: MetadataFunction<RecentlySoldMetadataOpts> = opts => ({
  title: forgePlaceInformation(opts?.place, {
    default: `Recently Sold houses, real estate & properties Canada`,
    province: `${opts?.place.province} Recently Sold houses, real estate & properties`,
    city: `${opts?.place.city} Recently Sold houses, real estate & properties`,
    neighbourhood: `${opts?.place.neighbourhood} ${opts?.place.city} Recently Sold houses, real estate & properties`,
  }),
  description: forgePlaceInformation(opts?.place, {
    default: `All recently sold homes and real estate in Canada. Find and see listings of the last prices for sold houses, condos & properties in the country`,
    city: `Full listing of recently sold homes and real estate in ${opts?.place.city}, ${opts?.place.province}. Find and see the latest prices for houses, condos & properties sold in the city `,
    neighbourhood: `Full listing of recently sold homes and real estate in ${opts?.place.neighbourhood} ${opts?.place.city}, ${opts?.place.province}. Find and see the latest prices for houses, condos & properties sold`,
  }),
  canonical: forgePlaceURL(opts?.place, "/recently-sold"),
  openGraph: {
    url: forgePlaceURL(opts?.place, "/recently-sold"),
    ...opts?.overrides?.openGraph,
  },
  ...opts?.overrides,
})

export default recentlySoldMetadata

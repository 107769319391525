import { MetadataFunction } from "../types"

const aboutMetadata: MetadataFunction = opts => ({
  title: "About Us",
  description:
    "A Canadian proptech platform that uses machine learning to generate home values on properties.",
  canonical: "https://www.honestdoor.com/about",
  openGraph: {
    url: "https://www.honestdoor.com/about",
    ...opts?.overrides?.openGraph,
  },
  ...opts?.overrides,
})

export default aboutMetadata

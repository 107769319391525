import { MetadataFunction } from "../types"

const forBusinessMetadata: MetadataFunction = opts => ({
  title: "For Business",
  description: "",
  canonical: "https://www.honestdoor.com/for-business",
  openGraph: {
    url: "https://www.honestdoor.com/for-business",
    ...opts?.overrides?.openGraph,
  },
  ...opts?.overrides,
})

export default forBusinessMetadata

import { NextSeoProps } from "next-seo"

export type Place = {
  province?: string
  city?: string
  neighbourhood?: string
}

export enum MetadataPage {
  Landing = "landing",
  Map = "map",
  Cities = "cities",
  Listing = "listing",
  Permits = "permits",
  Property = "property",
  PropertyEdit = "property-edit",
  RealEstate = "real-estate",
  ExclusiveListings = "exclusive-listings",
  RecentlySold = "recently-sold",
  Faq = "faq",
  Widget = "instant-home-value-widget",
  PrivateListings = "private-listings",
  Press = "press",
  Privacy = "privacy",
  Profile = "profile",
  About = "about",
  Terms = "terms",
  Create = "create",
  Blog = "blog",
  Sitemap = "sitemap",
  ForBusiness = "for-business",
  RocketMortgage = "rocket-mortgage",
  Homewise = "homewise-mortgage",
  SignIn = "sign-in",
  List = "list",
  BuyProperty = "buy-with-honestdoor",
  HomeValuationTool = "home-valuation-tool",
  HomebuildersAndCommercial = "homebuilders-and-commercial",
  MarketEvaluationReport = "market-evaluation-report",
}

export type PlaceFunction = (p: Place) => string

export type PageMetadataProps = NextSeoProps

export type MetadataOpts = {
  overrides?: Partial<PageMetadataProps>
}

export type MetadataFunction<T = Record<string, unknown>> = (
  opts?: MetadataOpts & T,
) => PageMetadataProps

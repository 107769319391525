import { EsProperty } from "~/generated/graphql"
import { formatStreet } from "~/utils/lib/string"

import { MetadataFunction } from "../types"
import { forgePlaceString } from ".."

type PropertyEditMetadataOpts = {
  property: EsProperty
}

const propertyEditMetadata: MetadataFunction<PropertyEditMetadataOpts> = opts => ({
  title: `Edit: ${formatStreet(opts.property?.unparsedAddress)}, ${forgePlaceString({
    province: opts?.property?.province,
    city: opts?.property?.cityName,
  })}`,
  canonical: `https://www.honestdoor.com/property/${opts?.property?.slug}/edit`,
  openGraph: {
    url: `https://www.honestdoor.com/property/${opts?.property?.slug}/edit`,
    ...opts?.overrides?.openGraph,
  },
  ...opts?.overrides,
  noindex: true,
  nofollow: true,
})

export default propertyEditMetadata

import { MetadataFunction } from "../types"

const rocketMortgageMetadata: MetadataFunction = opts => ({
  title: "Get the Best Canadian Mortgage Rates with Rocket Mortgage",
  description:
    "Get approved for a mortgage, refinance, or renewal with Canada's largest mortgage lender. Fill out an easy application for a great rate.",
  canonical: "https://www.honestdoor.com/rocket-mortgage",
  openGraph: {
    url: "https://www.honestdoor.com/rocket-mortgage",
    ...opts?.overrides?.openGraph,
  },
  ...opts?.overrides,
})

export default rocketMortgageMetadata

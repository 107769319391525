import { MetadataFunction } from "../types"

const homebuildersAndCommercialMetadata: MetadataFunction = opts => ({
  title: "List multiple properties for a flat fee",
  description: "",
  canonical: "https://www.honestdoor.com/homebuilders-and-commercial",
  openGraph: {
    url: "https://www.honestdoor.com/homebuilders-and-commercial",
    ...opts?.overrides?.openGraph,
  },
  ...opts?.overrides,
})

export default homebuildersAndCommercialMetadata

import { EsProperty, PrivateListing } from "~/generated/graphql"
import { formatStreet } from "~/utils/lib/string"

import { MetadataFunction } from "../types"
import { forgePlaceString } from ".."

type PropertyMetadataOpts = {
  property: EsProperty
  privateListing?: PrivateListing
}

const propertyMetadata: MetadataFunction<PropertyMetadataOpts> = opts => ({
  title: `${formatStreet(
    opts.property?.unparsedAddress ?? opts.privateListing?.streetAddress,
  )}, ${forgePlaceString({
    province: opts?.property?.province ?? opts?.privateListing?.province,
    city: opts?.property?.cityName ?? opts?.privateListing?.city,
  })}`,
  description: `View data for ${formatStreet(
    opts.property?.unparsedAddress ?? opts.privateListing?.streetAddress,
  )} such as beds, baths, home value estimates, sold price history, rental price esitmates, city assessments, tax information, development permits, and more.`,
  canonical: `https://www.honestdoor.com/property/${opts?.property?.slug ?? opts?.privateListing?.slug}`,
  openGraph: {
    url: `https://www.honestdoor.com/property/${opts?.property?.slug ?? opts?.privateListing?.slug}`,
    ...opts?.overrides?.openGraph,
  },
  ...opts?.overrides,
})

export default propertyMetadata

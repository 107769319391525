import { MetadataFunction } from "../types"

const createMetadata: MetadataFunction = opts => ({
  title: "List a Property",
  description:
    "List your house, new construction, commercial, or land property for sale for free. List for sale by owner, agent, or builder. What price should I list my home for?",
  canonical: "https://www.honestdoor.com/create",
  openGraph: {
    url: "https://www.honestdoor.com/create",
    ...opts?.overrides?.openGraph,
  },
  ...opts?.overrides,
})

export default createMetadata

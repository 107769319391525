import { forgePlaceURL, forgePlaceTitle, forgePlaceString } from ".."
import { MetadataFunction, Place } from "../types"

type SitemapMetadataOpts = {
  place: Place
}

const sitemapMetadata: MetadataFunction<SitemapMetadataOpts> = opts => ({
  title: forgePlaceTitle(opts?.place, {
    default: "Sitemap",
  }),
  description: `${forgePlaceString(opts?.place)} List of all pages on the site`.trim(),
  canonical: forgePlaceURL(opts?.place, "/sitemap", false),
  openGraph: {
    url: forgePlaceURL(opts?.place, "/sitemap", false),
    ...opts?.overrides?.openGraph,
  },
  ...opts?.overrides,
})

export default sitemapMetadata

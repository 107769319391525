import { NextSeoProps } from "next-seo"
import { TBlog } from "~/components/layouts/blog/BlogEntry"
import { MetadataFunction } from "../types"

type BlogMetadataOpts = {
  post?: TBlog
  isArchive?: boolean
}

const blogMetadata: MetadataFunction<BlogMetadataOpts> = opts => ({
  title: forgeBlogTitle(opts),
  description: forgeBlogDescription(opts),
  canonical: forgeBlogURL(opts),
  openGraph: {
    ...forgeBlogOpenGraph(opts),
    ...opts?.overrides?.openGraph,
  },
  ...opts?.overrides,
})

const forgeBlogTitle = (opts: BlogMetadataOpts): string => {
  if (opts?.isArchive) return "Blog Archive"

  if (!opts?.post) return "Blog"

  return opts?.post?.title + " - Blog"
}

const forgeBlogURL = (opts: BlogMetadataOpts): string => {
  if (opts?.isArchive) return "https://www.honestdoor.com/blog/archive"

  if (!opts?.post) return "https://www.honestdoor.com/blog"

  return `https://www.honestdoor.com/blog/${opts?.post?.slug}`
}

const forgeBlogDescription = (opts: BlogMetadataOpts): string => {
  if (opts?.isArchive) return "Browse archived posts"

  if (!opts?.post)
    return "Stay up to date on all the latest news and developments in the real estate industry."

  return opts?.post?.excerpt
}

const forgeBlogOpenGraph = (opts: BlogMetadataOpts): NextSeoProps["openGraph"] => {
  const defaults: NextSeoProps["openGraph"] = {
    url: forgeBlogURL(opts),
  }

  if (opts?.isArchive) return { ...defaults, type: "website" }

  if (!opts?.post) return { ...defaults, type: "website" }

  return {
    ...defaults,
    type: "article",
    article: {
      publishedTime: opts?.post?.date,
      authors: [opts.post.author?.name],
      tags: opts?.post?.tags,
    },
    images: [
      {
        url: opts?.post?.coverImage?.url,
        alt: opts?.post?.title,
        height: Number(opts?.post?.coverImage?.height),
        width: Number(opts?.post?.coverImage?.width),
      },
    ],
  }
}

export default blogMetadata

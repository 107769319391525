import startCase from "lodash/startCase"
import truncate from "lodash/truncate"
import { CreaListing } from "~/generated/graphql"
import { formatStreet } from "~/utils/lib/string"

import { MetadataFunction } from "../types"
import { forgePlaceString } from ".."

type ListingMetadataOpts = {
  listing: CreaListing
}

const listingMetadata: MetadataFunction<ListingMetadataOpts> = opts => ({
  title: `${formatStreet(opts?.listing?.address?.streetAddress)}, ${forgePlaceString({
    province: opts?.listing?.address?.province,
    city: opts?.listing?.address?.city,
  })} - ${startCase(opts?.listing?.transactionType ?? "For Sale")}`,
  description: truncate(opts?.listing?.publicRemarks, { length: 150 }),
  canonical: `https://www.honestdoor.com/listing/${opts?.listing?.slug}`,
  openGraph: {
    url: `https://www.honestdoor.com/listing/${opts?.listing?.slug}`,
    images: opts?.listing?.photo?.propertyPhoto
      ?.sort(photo => Number(photo.sequenceId) ?? 0)
      .map(photo => ({
        url: photo?.largePhotoUrl,
        alt: photo?.description,
      })),
    ...opts?.overrides?.openGraph,
  },
  ...opts?.overrides,
})

export default listingMetadata

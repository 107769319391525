import { FilterCategory } from "~/store/mapFilters"
import { forgePlaceTitle } from ".."
import { MetadataFunction, Place } from "../types"

type MapMetadataOpts = {
  category: FilterCategory
  place: Place
}

const mapMetadata: MetadataFunction<MapMetadataOpts> = opts => ({
  title: forgePlaceTitle(opts?.place, {
    // @TODO: Add "& Real Estate Listings" when crea is launched.
    default: `Map Search for ${getMapTitleSuffix(opts.category)}`,
  }),
  description:
    "Map view of houses, condos, commercial properties, land. Square footage, bed and baths, last sold price, permits, neighbourhood trends, houses for sale.",
  canonical: "https://www.honestdoor.com/map",
  openGraph: {
    url: "https://www.honestdoor.com/map",
    ...opts?.overrides?.openGraph,
  },
  ...opts?.overrides,
})

const getMapTitleSuffix = (category: FilterCategory) => {
  switch (category) {
    case FilterCategory.ForSale:
      return "Homes for Sale"
    case FilterCategory.Properties:
      return "Property Data"
    case FilterCategory.RecentlySold:
      return "Recently Sold Properties"
    default:
      return ""
  }
}

export default mapMetadata

import { forgePlaceInformation, forgePlaceURL } from ".."
import { MetadataFunction, Place } from "../types"

type RealEstateMetadataOpts = {
  place: Place
}

const realEstateMetadata: MetadataFunction<RealEstateMetadataOpts> = opts => ({
  title: forgePlaceInformation(opts?.place, {
    default: `Real Estate: Condos & Houses for Sale`,
    province: `${opts?.place.province} Real Estate: Condos & Houses for Sale`,
    city: `${opts?.place.city} Real Estate: Condos & Houses for Sale`,
    neighbourhood: `${opts?.place.neighbourhood} ${opts?.place.city} Real Estate: Condos & Houses for Sale`,
  }),
  description: forgePlaceInformation(opts?.place, {
    default: `${opts?.place.province} Real Estate for sale - check out the prices of condos, houses, townhouses, duplexes, and bungalows, and buy the best properties in the province ${opts?.place.province}`,
    city: `${opts?.place.city} Real Estate for sale - check out the prices of condos, houses, townhouses, duplexes, and bungalows, and buy the best properties in ${opts?.place.city}, ${opts?.place.province}`,
    neighbourhood: `${opts?.place.neighbourhood} ${opts?.place.city}, ${opts?.place.province} Real Estate for sale - check out the prices of condos, houses, townhouses, duplexes, and bungalows, and buy the best properties`,
  }),
  canonical: forgePlaceURL(opts?.place, "/real-estate"),
  openGraph: {
    url: forgePlaceURL(opts?.place, "/real-estate"),
  },
  ...opts?.overrides,
})

export default realEstateMetadata

import { MetadataPage } from "../types"
import blogMetadata from "./blog"
import citiesMetadata from "./cities"
import createMetadata from "./create"
import faqMetadata from "./faq"
import forBusinessMetadata from "./for-business"
import listMetadata from "./list"
import homewiseMetadata from "./homewise"
import landingMetadata from "./landing"
import listingMetadata from "./listing"
import mapMetadata from "./map"
import permitsMetadata from "./permits"
import pressMetadata from "./press"
import privacyMetadata from "./privacy"
import privateListingsMetadata from "./private-listings"
import profileMetadata from "./profile"
import propertyEditMetadata from "./property-edit"
import propertyMetadata from "./property"
import realEstateMetadata from "./real-estate"
import exclusiveListingsMetadata from "./exclusive-listings"
import recentlySoldMetadata from "./recently-sold"
import rocketMortgageMetadata from "./rocket-mortgage"
import sitemapMetadata from "./sitemap"
import aboutMetadata from "./about"
import termsMetadata from "./terms"
import widgetMetadata from "./widget"
import { signInMetadata } from "./auth"
import buyPropertyMetadata from "~/utils/seo/metadata/buy-property"
import homeValuationToolMetadata from "~/utils/seo/metadata/home-valuation-tool"
import homebuildersAndCommercialMetadata from "~/utils/seo/metadata/homebuilders-and-commercial"
import marketEvaluationReportMetadata from "~/utils/seo/metadata/market-evaluation-report"

const metadata = {
  [MetadataPage.Landing]: landingMetadata,
  [MetadataPage.Map]: mapMetadata,
  [MetadataPage.Cities]: citiesMetadata,
  [MetadataPage.Listing]: listingMetadata,
  [MetadataPage.Permits]: permitsMetadata,
  [MetadataPage.Property]: propertyMetadata,
  [MetadataPage.PropertyEdit]: propertyEditMetadata,
  [MetadataPage.RealEstate]: realEstateMetadata,
  [MetadataPage.ExclusiveListings]: exclusiveListingsMetadata,
  [MetadataPage.RecentlySold]: recentlySoldMetadata,
  [MetadataPage.Faq]: faqMetadata,
  [MetadataPage.Widget]: widgetMetadata,
  [MetadataPage.PrivateListings]: privateListingsMetadata,
  [MetadataPage.Press]: pressMetadata,
  [MetadataPage.Privacy]: privacyMetadata,
  [MetadataPage.Profile]: profileMetadata,
  [MetadataPage.About]: aboutMetadata,
  [MetadataPage.Terms]: termsMetadata,
  [MetadataPage.Create]: createMetadata,
  [MetadataPage.Blog]: blogMetadata,
  [MetadataPage.Sitemap]: sitemapMetadata,
  [MetadataPage.ForBusiness]: forBusinessMetadata,
  [MetadataPage.RocketMortgage]: rocketMortgageMetadata,
  [MetadataPage.Homewise]: homewiseMetadata,
  [MetadataPage.SignIn]: signInMetadata,
  [MetadataPage.List]: listMetadata,
  [MetadataPage.BuyProperty]: buyPropertyMetadata,
  [MetadataPage.HomeValuationTool]: homeValuationToolMetadata,
  [MetadataPage.HomebuildersAndCommercial]: homebuildersAndCommercialMetadata,
  [MetadataPage.MarketEvaluationReport]: marketEvaluationReportMetadata,
}

export default metadata

import { MetadataFunction } from "../types"

const homeValuationToolMetadata: MetadataFunction = opts => ({
  title: "Home Valuation Tool",
  description: "",
  canonical: "https://www.honestdoor.com/home-valuation-tool",
  openGraph: {
    url: "https://www.honestdoor.com/home-valuation-tool",
    ...opts?.overrides?.openGraph,
  },
  ...opts?.overrides,
})

export default homeValuationToolMetadata

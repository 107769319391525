import { MetadataFunction } from "../types"

const faqMetadata: MetadataFunction = opts => ({
  title: "FAQ",
  description: "Frequently asked questions about HonestDoor",
  canonical: "https://www.honestdoor.com/faq",
  openGraph: {
    url: "https://www.honestdoor.com/faq",
    ...opts?.overrides?.openGraph,
  },
  ...opts?.overrides,
})

export default faqMetadata

import { forgePlaceInformation, forgePlaceURL } from ".."
import { MetadataFunction, Place } from "../types"

type ExclusiveListingsMetadataOpts = {
  place: Place
}

const exclusiveListingsMetadata: MetadataFunction<ExclusiveListingsMetadataOpts> = opts => ({
  title: forgePlaceInformation(opts?.place, {
    default: `HonestDoor Listings on REALTOR.ca: Condos & Houses for Sale`,
    province: `${opts?.place.province} HonestDoor Listings on REALTOR.ca: Condos & Houses for Sale`,
    city: `${opts?.place.city} HonestDoor Listings on REALTOR.ca: Condos & Houses for Sale`,
    neighbourhood: `${opts?.place.neighbourhood} ${opts?.place.city} HonestDoor Listings on REALTOR.ca: Condos & Houses for Sale`,
  }),
  description: forgePlaceInformation(opts?.place, {
    default: `${opts?.place.province} HonestDoor Listings on REALTOR.ca - check out the prices of condos, houses, townhouses, duplexes, and bungalows, and buy the best properties in the province ${opts?.place.province}`,
    city: `${opts?.place.city} HonestDoor Listings on REALTOR.ca - check out the prices of condos, houses, townhouses, duplexes, and bungalows, and buy the best properties in ${opts?.place.city}, ${opts?.place.province}`,
    neighbourhood: `${opts?.place.neighbourhood} ${opts?.place.city}, ${opts?.place.province} HonestDoor Listings on REALTOR.ca - check out the prices of condos, houses, townhouses, duplexes, and bungalows, and buy the best properties`,
  }),
  canonical: forgePlaceURL(opts?.place, "/exclusive-listings"),
  openGraph: {
    url: forgePlaceURL(opts?.place, "/exclusive-listings"),
  },
  ...opts?.overrides,
})

export default exclusiveListingsMetadata

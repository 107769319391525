import { forgePlaceInformation, forgePlaceURL, getCurrentMonthYear } from ".."
import { MetadataFunction, Place } from "../types"

type CitiesMetadataOpts = {
  place: Place
}

//prettier-ignore
const citiesMetadata: MetadataFunction<CitiesMetadataOpts> = opts => ({
  title: forgePlaceInformation(opts?.place, {
    default: `Canada housing market: real estate market ${getCurrentMonthYear()} report`,
    province: `${opts?.place.province} housing market: real estate market ${getCurrentMonthYear()} report`,
    city: `${opts?.place.city} housing market: real estate market ${getCurrentMonthYear()} report`,
    neighbourhood: `${opts?.place.neighbourhood}, ${opts?.place.province} housing market: real estate market ${getCurrentMonthYear()} report`,
  }),
  description: forgePlaceInformation(opts?.place, {
    default: "Canada real estate & housing market report. Forecasts & trends — diagrams and historical graphs of price changes for duplexes, condos, townhouses, and bungalows",
    province: `${opts?.place.province} real estate & housing market report. Forecasts & trends — diagrams and historical graphs of price changes for duplexes, condos, townhouses, and bungalows in ${opts?.place.province}`,
    city: `${opts?.place.city} real estate & housing market report. Forecasts & trends — diagrams and historical graphs of price changes for duplexes, condos, townhouses, and bungalows in ${opts?.place.city}, ${opts?.place.province}`,
    neighbourhood: `Real estate & housing market report ${opts?.place.neighbourhood}, ${opts?.place.city}, ${opts?.place.province}. Forecasts & trends — diagrams and historical graphs of price changes for duplexes, condos, townhouses, and bungalows`,
  }),
  canonical: forgePlaceURL(opts?.place, "/cities"),
  openGraph: {
    url: forgePlaceURL(opts?.place, "/cities"),
    ...opts?.overrides?.openGraph,
  },
  ...opts?.overrides,
})

export default citiesMetadata

import { MetadataFunction } from "../types"

const pressMetadata: MetadataFunction = opts => ({
  title: "Press Room",
  description: "HonestDoor in the news, media stories, press and much more",
  canonical: "https://www.honestdoor.com/press",
  openGraph: {
    url: "https://www.honestdoor.com/press",
    ...opts?.overrides?.openGraph,
  },
  ...opts?.overrides,
})

export default pressMetadata
